<template>
  <div class="add-or-updata">
    <common-dialog
      :title="!dataForm.newsId ? '新增' : '修改'"
      width="1220px"
      :close-on-click-modal="false"
      :is-visible.sync="visible"
    >
      <div style="border-top: #e8e8e8 solid 1px; padding: 30px 40px 10px 50px">
        <el-form
          ref="ruleForm"
          :model="dataForm"
          :rules="dataRule"
          label-width="100px"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="首图：" prop="position">
                <div class="firstImage-wrap">
                  <div class="firstImage">
                    <img
                      v-if="dataForm.firstImage"
                      :src="dataForm.firstImage"
                      alt=""
                    >
                    <i v-else class="el-icon-picture-outline" />
                  </div>
                  <div class="updata-but">
                    <el-button size="small" type="primary" @click="onUpdaImage">{{
                      dataForm.firstImage ? "修改图片" : "上传图片"
                    }}</el-button>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="15">
              <el-form-item label="标题：" prop="title">
                <el-input
                  v-model="dataForm.title"
                  size="small"
                  placeholder="请输入新闻标题"
                  style="width: 560px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="排序：" prop="sort">
                <el-input
                  v-model="dataForm.sort"
                  type="number"
                  size="small"
                  :min="0"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  placeholder="请输入排序(数字，越小越靠前)"
                  style="width: 300px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="15">
              <el-form-item label="新闻来源：" prop="title">
                <el-input
                  v-model="dataForm.source"
                  size="small"
                  placeholder="请输入新闻来源"
                  style="width: 560px"
                />
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="新闻分类：" prop="dataName">
                <el-select
                  v-model="dataForm.dataName"
                  placeholder="请选择新闻分类"
                  clearable
                  style="width: 300px"
                >
                  <el-option label="行业新闻" value="1" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="摘要：" prop="abstract">
                <el-input
                  v-model="dataForm.abstract"
                  type="textarea"
                  maxlength="300"
                  show-word-limit
                  size="small"
                  placeholder="新闻摘要"
                  style="width: 1014px"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="新闻内容："
            prop="remarks"
          >
            <ueditor-wrap
              initial-frame-width="1014"
              initial-frame-height="300"
              :ueditor-val="dataForm.remarks"
              @ueditorMsg="ueditorMsg"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="permission-dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="dataFormSubmit()"
        >确定</el-button>
        <el-button
          size="small"
          style="margin-left: 20px"
          @click="cancel"
        >取消</el-button>
      </div>
    </common-dialog>
    <ueditor-upload-image
      ref="uploadImage"
      :view-image="route"
      @successUpload="loadingJPGSuccessed"
      @onImageHtml="onImageHtml"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: '',
      visible: false,
      dataForm: {
        newsId: '',
        firstImage: '',
        cityName: '',
        title: '',
        dataType: '',
        dataName: '行业新闻',
        abstract: '',
        accountId: '',
        remarks: '',
        source: '',
        sort: ''
      },
      dataRule: {
        title: [
          { required: true, message: '新闻标题不能为空', trigger: 'blur' }
        ],
        dataType: [
          { required: true, message: '新闻分类不能为空', trigger: 'blur' }
        ],
        abstract: [
          { required: true, message: '摘要不能为空', trigger: 'blur' }
        ],
        dataName: [
          { required: true, message: '新闻分类不能为空', trigger: 'change' }
        ],
        sort: [{ required: true, message: '排序不能为空', trigger: 'blur' }]
      }
    }
  },
  created() {},
  methods: {
    onUpdaImage() {
      this.$refs.uploadImage.open()
    },
    onImageHtml() {
      this.$set(this.dataForm, 'firstImage', this.route)
    },
    loadingJPGSuccessed(res) {
      this.route = res
    },
    ueditorMsg(val) {
      this.dataForm.remarks = val
    },
    open(id) {
      this.visible = true
      this.dataForm.newsId = id
      this.dataForm.remarks = ''
      if (id > 0) {
        this.$http.post(`api/News/GetSingleNews?newsId=${id}`).then((data) => {
          if (data && data.code === 1) {
            this.dataForm = data.data
            this.ueditorMsg(this.dataForm.remarks)
            // this.dataForm.apiType = data.data.apiType.toString()
          } else {
            this.$message.error(data.msg)
          }
        })
      } else {
        this.resetForm()
      }
    },
    // 表单提交
    dataFormSubmit() {
      if (!this.dataForm.firstImage) {
        this.$message({
          message: '请上传图片',
          type: 'warning',
          duration: 700
        })
        return
      } else if (!this.dataForm.remarks) {
        this.$message({
          message: '新闻内容不能为空',
          type: 'warning',
          duration: 700
        })
        return
      }
      // console.log(this.dataForm.ueditorMsg, '5421324545465')
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$http
            .post(
              `api/News/${!this.dataForm.newsId ? 'AddNews' : 'UpdateNews'}`,
              this.dataForm
            )
            .then((data) => {
              if (data && data.code === 1) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 700
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            })
        } else {
          // this.dataForm.taxSourceCityName = ''
        }
      })
    },
    resetForm() {
      this.dataForm = {}
      this.dataForm.dataType = 1
      this.dataForm.dataName = '行业新闻'
    },
    cancel() {
      this.visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-row {
  padding-bottom: 10px;
}
::v-deep .el-input__inner {
  height: 32px;
  line-height: 32px;
}
.firstImage-wrap {
  display: flex;
  align-items: flex-end;
  .firstImage {
    margin-right: 20px;
    width: 260px;
    height: 200px;
    border: 2px dashed #dedede;
    text-align: center;
    line-height: 200px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    .el-icon-picture-outline {
      font-size: 60px;
      color: #dedede;
    }
  }
}
</style>
